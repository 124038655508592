import React, { CSSProperties, FC } from "react";

import { Row, Col, Grid } from "antd";
import { FaFacebookF, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

import VisuallyHidden from "./VisuallyHidden";

import { SIGLA_WEBSITE } from "../constants";

import { primary } from "../styles/colors";
import { fontSizes } from "../styles/fonts";

import lastUpdated from "../assets/last-updated.json";
import socialMedia from "../assets/social-media.json";

const { useBreakpoint } = Grid;

const footerStyle: CSSProperties = {
  borderTop: "1px solid #d9d9d9",
  boxSizing: "border-box",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const titleStyle: CSSProperties = {
  fontWeight: 600,
  color: primary,
  fontSize: fontSizes.font_size_7,
  textAlign: "center",
};

const lastUpdatedStyle: CSSProperties = {
  padding: "0 16px",
  textAlign: "center",
  fontSize: fontSizes.font_size_6,
};

const copyRightStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTop: "1px solid #d9d9d9",
  fontSize: fontSizes.font_size_6,
  minHeight: "50px",
  width: "100%",
  textAlign: "center",
};

const CLAS_IMG =
  "https://sigla.georgetown.domains/gateway/wp-content/uploads/2020/04/CLAS-New-Logo-Dec-2018.png";

const GEORGETOWN_IMG =
  "https://sigla.georgetown.domains/gateway/wp-content/uploads/2020/04/georgetown-university-vector-logo-300x167.png";

const Footer: FC = () => {
  const { xs } = useBreakpoint();
  return (
    <footer id="sigla-app-footer" style={footerStyle}>
      <div style={{ padding: "50px 0", maxWidth: "1140px" }}>
        <Row gutter={[0, xs ? 32 : 16]}>
          <Col xs={24} sm={24} md={8}>
            <Row gutter={[16, 16]} justify="center">
              <Col span={24}>
                <div style={titleStyle}>Social Media</div>
              </Col>
              <Col>
                <a
                  href={socialMedia.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Twitter"
                >
                  <div className="social-media-icon-container">
                    <VisuallyHidden icon={<FaTwitter />} text="Twitter" />
                  </div>
                </a>
              </Col>
              <Col>
                <a
                  href={socialMedia.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <div className="social-media-icon-container">
                    <VisuallyHidden icon={<FaFacebookF />} text="Facebook" />
                  </div>
                </a>
              </Col>
              <Col>
                <a
                  href={socialMedia.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <div className="social-media-icon-container">
                    <VisuallyHidden icon={<FaInstagram />} text="Instagram" />
                  </div>
                </a>
              </Col>
              <Col>
                <a
                  href={socialMedia.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="LinkedIn"
                >
                  <div className="social-media-icon-container">
                    <VisuallyHidden icon={<FaLinkedin />} text="LinkedIn" />
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Row gutter={[0, 0]} justify="center">
              <Col span={24}>
                <div style={titleStyle}>Hosted by</div>
              </Col>
              <Col>
                <img
                  className="georgetown-logo"
                  alt="Georgetown University Logo"
                  src={GEORGETOWN_IMG}
                />
              </Col>
              <Col span={24}>
                <img
                  className="georgetown-logo"
                  alt="Center for Latin American Studies Logo"
                  src={CLAS_IMG}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Row gutter={[0, 16]} justify="center">
              <Col span={24}>
                <div style={titleStyle}>About</div>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 0]}>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <a href={`${SIGLA_WEBSITE}about/`} className="about-link">
                      SIGLA
                    </a>
                  </Col>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <a href={`${SIGLA_WEBSITE}meet-the-team/`} className="about-link">
                      Team
                    </a>
                  </Col>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <a href={`${SIGLA_WEBSITE}contact/`} className="about-link">
                      Contact
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {lastUpdated.date && (
            <Col span={24}>
              <div style={lastUpdatedStyle}>
                {`Updates last made to the site on ${lastUpdated.date}.${xs ? " " : ""}`}
                {!xs && <br />}
                Our{" "}
                <a href={`${SIGLA_WEBSITE}research-methodology/`} rel="noopener noreferrer">
                  Research Methodology
                </a>{" "}
                has more information about our updating and verification procedures.
              </div>
            </Col>
          )}
        </Row>
      </div>
      <div style={copyRightStyle}>Copyright © 2023 SIGLA</div>
    </footer>
  );
};

export default Footer;
