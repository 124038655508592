import React, { FC, useState, CSSProperties, Key } from "react";

import { MenuOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Grid, Button, Menu } from "antd";
import _ from "lodash";
import { Link } from "react-router-dom";

import { SIGLA_WEBSITE, SIGLA_ROUTES, SIGLA_ROUTES_DISPLAY } from "../constants";

import SiglaLogo from "../assets/sigla-logo.png";

const { useBreakpoint } = Grid;
const { SubMenu } = Menu;

const navBarStyle: CSSProperties = {
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 2,
};

const menuStyle: CSSProperties = {
  border: "unset",
};

const subMenu = (title: string, isHorizontal: boolean | undefined): React.ReactNode => {
  if (!isHorizontal) {
    return title;
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{title}</span>
      <span style={{ marginLeft: 10 }}>
        <DownOutlined style={{ fontSize: 12 }} />
      </span>
    </div>
  );
};

const NavBar: FC = () => {
  const { xl } = useBreakpoint();
  const [open, setOpen] = useState<boolean>(false);
  const onButtonClick = () => setOpen((open) => !open);
  const onMenuClick = ({ key }: { key: string }) => {
    if (!xl && _.includes(_.values(SIGLA_ROUTES), key)) {
      setOpen(false);
    }
  };
  //keep < 1 submenu open
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const subMenuKeys = ["about-menu", "explore-data-menu", "user-resources-menu", "contact-menu"];
  const onOpenChange = (keys: Key[]) => {
    const latestOpenKey = _.find(keys, (key) => !_.includes(openKeys, key));
    if (!_.includes(subMenuKeys, latestOpenKey)) {
      setOpenKeys(keys as string[]);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey as string] : []);
    }
  };
  const onSubmenuTitleClick = ({ key }: { key: string }) => {
    setOpenKeys([key]);
  };
  const buttonStyle: CSSProperties = {
    display: xl ? "none" : "block",
  };

  const horizontalMenuStyle: CSSProperties = {
    ...menuStyle,
  };

  const verticalMenuStyle: CSSProperties = {
    ...menuStyle,
    transition: "max-height 0.3s ease-in",
    width: "100%",
    maxHeight: open ? "none" : "0px",
    padding: "1px",
    overflow: "hidden",
    display: open ? "block" : "none",
  };

  const imgStyle: CSSProperties = {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
  };

  const imgContainerStyle: CSSProperties = {
    maxWidth: "450px",
    maxHeight: "63px",
  };
  return (
    <div style={{ padding: xl ? "16px 32px" : "16px", backgroundColor: "white" }}>
      <nav style={navBarStyle}>
        <a href={SIGLA_WEBSITE} rel="noopener noreferrer">
          <div style={imgContainerStyle}>
            <img style={imgStyle} alt="SIGLA Home" src={SiglaLogo} />
          </div>
        </a>
        <div style={buttonStyle}>
          <Button
            type="primary"
            size="large"
            aria-label={`${open ? "Hide" : "Show"} menu`}
            icon={open ? <CloseOutlined /> : <MenuOutlined />}
            onClick={onButtonClick}
          />
        </div>
        <Menu
          className="main-menu"
          style={xl ? horizontalMenuStyle : verticalMenuStyle}
          theme="light"
          mode={xl ? "horizontal" : "inline"}
          onClick={onMenuClick}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          triggerSubMenuAction="hover"
          // This prop name may change
          disabledOverflow={true}
        >
          <SubMenu
            key="about-menu"
            title={subMenu("About", xl)}
            popupClassName="submenu"
            onTitleClick={onSubmenuTitleClick}
          >
            <Menu.Item key="about">
              <a href={`${SIGLA_WEBSITE}about/`}>About SIGLA</a>
            </Menu.Item>
            <Menu.Item key="meet-the-team">
              <a href={`${SIGLA_WEBSITE}meet-the-team/`}>Meet the Team</a>
            </Menu.Item>
            <Menu.Item key="join-us">
              <a href={`${SIGLA_WEBSITE}join-us/`}>Join Us</a>
            </Menu.Item>
            <Menu.Item key="sigla-alumni">
              <a href={`${SIGLA_WEBSITE}sigla-alumni/`}>SIGLA Alumni</a>
            </Menu.Item>
            <Menu.Item key="acknowledgements">
              <a href={`${SIGLA_WEBSITE}acknowledgements/`}>Acknowledgements</a>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="explore-data-menu"
            title={subMenu("Explore Data", xl)}
            popupClassName="submenu"
            onTitleClick={onSubmenuTitleClick}
          >
            <Menu.Item key={SIGLA_ROUTES.byCountry}>
              <Link to={`/${SIGLA_ROUTES.byCountry}`}>
                {SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.byCountry].title}
              </Link>
            </Menu.Item>
            <Menu.Item key={SIGLA_ROUTES.byInstitution}>
              <Link to={`/${SIGLA_ROUTES.byInstitution}`}>
                {SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.byInstitution].title}
              </Link>
            </Menu.Item>
            <Menu.Item key={SIGLA_ROUTES.compare}>
              <Link to={`/${SIGLA_ROUTES.compare}`}>
                {SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.compare].title}
              </Link>
            </Menu.Item>
            <Menu.Item key={SIGLA_ROUTES.customBrowse}>
              <Link to={`/${SIGLA_ROUTES.customBrowse}`}>
                {SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.customBrowse].title}
              </Link>
            </Menu.Item>
            <SubMenu
              key="regional-data-menu"
              title="Regional Data"
              popupClassName="submenu"
              onTitleClick={onSubmenuTitleClick}
            >
              <Menu.Item key="executive-report">
                <a href={`${SIGLA_WEBSITE}executive-report/`}>Annual Chief Executive Report</a>
              </Menu.Item>
            </SubMenu>
          </SubMenu>
          <SubMenu
            key="methodology-menu"
            title={subMenu("Methodology", xl)}
            popupClassName="submenu"
            onTitleClick={onSubmenuTitleClick}
          >
            <Menu.Item key="research-methodology">
              <a href={`${SIGLA_WEBSITE}research-methodology/`}>Research Methodology</a>
            </Menu.Item>
            <Menu.Item key="technical-codebook">
              <a href={`${SIGLA_WEBSITE}technical-codebook/`}>Technical Codebook</a>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="user-resources-menu"
            title={subMenu("User Resources", xl)}
            popupClassName="submenu"
            onTitleClick={onSubmenuTitleClick}
          >
            <Menu.Item key="user-guide">
              <a href={`${SIGLA_WEBSITE}user-guide/`}>User Guide</a>
            </Menu.Item>
            <Menu.Item key="institutioal-matrix">
              <a href={`${SIGLA_WEBSITE}institutional-matrix/`}>Institutional Matrix</a>
            </Menu.Item>
            <Menu.Item key="release-updates">
              <a href={`${SIGLA_WEBSITE}release-updates/`}>Release Updates</a>
            </Menu.Item>
            <Menu.Item key="cite-sigla">
              <a href={`${SIGLA_WEBSITE}cite-sigla/`}>Cite SIGLA</a>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="contact-menu"
            title={subMenu("Contact", xl)}
            popupClassName="submenu"
            onTitleClick={onSubmenuTitleClick}
          >
            <Menu.Item key="general-inquiries">
              <a href={`${SIGLA_WEBSITE}contact/`}>General Inquiries</a>
            </Menu.Item>
            <Menu.Item key="report-an-issue">
              <a href={`${SIGLA_WEBSITE}report-an-issue/`}>Report an Issue</a>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key={SIGLA_ROUTES.myPinnedData}>
            <Link to={`/${SIGLA_ROUTES.myPinnedData}`}>
              {SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.myPinnedData].title}
            </Link>
          </Menu.Item>
        </Menu>
      </nav>
    </div>
  );
};

export default NavBar;
